import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public access_tocken = "";
  public Server_URL = "http://localhost:55245/";
  public Quotation_Format="Format1";
  public System_mac_address="Not found"
  public Area_Map: boolean = false;
  public NT_Bill_Format = "NT_Format1"
  public Bill_Format = "Format1"
  public Bill_Format1 = "Format6";
  public Vadi_Format = "Format1";
  public check_Unicod: boolean = false;


  public TCS_Enable: boolean = true;
  public Balance_SMS = "false";


  public key_value="";

  public Intrest_Format="Flat"

  public NT_Printer: string = "false";
  public NT_Address: String = "http://localhost:55245/";

  public DB_Vadi_display: boolean = false;
  public Print_Button: boolean = false;

  public Ledger_Update:boolean=true;
  public Cust_Amt: boolean = false;
  public Lfrom = "34";
  public Top = 50;

  public Logo_location = "Top_qubha";
  public Logo_Name = "Qubha";
  public Logo_location_Loin = "Chef";
  public Logo_Sub_Name = "Soft";
  public Phone_No = "9597436220";

  public Qmenu: boolean = true;
  public STamilName: boolean = false;
  public Type_Based_Bill_No: boolean = false;
  public Item_Rate_Update: boolean = true;
  public Check_Stock: boolean = false;
  
  public Image_disp: boolean = false;
  public Ledger_Type = "1";

  public Group_Enable: boolean = false;
  public Print_Bill: boolean = false;
  
  public Save_Print: boolean = true;
  public Save_Print_Mobile: boolean = false;
  public Save_New: boolean = false;

  public  Stockbase_Sales: boolean = false;

  public  mobile_menu: boolean = false;

  
  public from_customer_page:boolean=false;
  public W_height = 0;
  public W_width = 0;

  public SM_Row = [];
  public Sales_Disp_Text2_Visblle: boolean = false;
  public Sales_Disp_Text3_Visblle: boolean = false;
  
  public Loan_Issue_Page="/collection/emi-loan-issue";
  public Add_Receipt_Page="/transaction/add-receipt-entry-two";
  public U_Rights=""
  public Item_Group_ = "1";
  public isadd_Page:boolean=false;
  lenth: number = 0;
  public GST_Code = "33";
  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {


    if (this.getData()) {

      this.data = JSON.parse(localStorage.getItem('User_Data'));

      try {
        this.Customer_Area = localStorage.getItem('Area');
      } catch { }
      this.Company = "_" + this.data.UM_Company;
      this.GST_Code=this.data.CM_State_Code;
    }


    this.W_height = window.innerHeight;

    this.W_width = window.innerWidth;

    var Today_Date = this.datePipe.transform(new Date(), "dd-MMM-yyyy");

    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.M_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.T_Date_Time = this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm");


    this.exp_Date = this.datePipe.transform(new Date(), "MM/yy");
    this.Pay_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.Sal_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");



    this.Y_Date = this.datePipe.transform(new Date().getDate() - 1, "dd-MMM-yyyy");
    this.Today_Date = Today_Date;


  
    this.S_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.S_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.From_Date = { formatted: Today_Date };
    this.To_Date = { formatted: Today_Date };
    this.Date = { formatted: Today_Date };
    this.Date1 = { formatted: Today_Date };


    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.load_themes('');



  }
public M_From="";
  public lable_ = "";
  public item = "";
  public Filter_Type = "contain";

  public openModal: boolean = false;
  Open_Modal(Title, page) {
    this.openModal = true;
    $('#small_Module').modal('show');
  }

  Close_Modal() {
    this.openModal = false;
    $('#small_Module').modal('hide');
  }

  public Header_Disp = "";

  public add_item_name = "/add-items1";
  btndisable: boolean = false;
  isload: boolean = false;
  public Company = "";
  public CREATED_BY = "";
  public Ref_ID = "0";
  public isadd = "0";
  public Edit_Row: any = {};
  public Edit_Row1: any = {};

  public Selected_Row: any = {};
  public Default_ref_ID: string = "Role";
  public URL_Location = "";

  public disp_category:boolean=false;
  public img_visible: boolean = false;

  
  public Date;
  public Date1;
  public From_Date;
  public To_Date;

  public User_Type = "Admin";
  public Document_Type = "Front_Banner";
  public Phone_Pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public Receipt_Print="false";

  public Side_Menu: boolean = false;
  public Today_Date = "";
  public S_From = "";
  public S_To = "";
  public FS_Date = "2021-04-01";

  public S_Bill_Type = "All";
  public S_Thari_Status = "All";
  public Calibration_Standard ="";
  public Category = "All";

  public S_Pay_Mode = "0";
  public S_Bill_Mode = "All";
  public S_Area = "All"
  public S_Sales_Order_by = "Bill_No";
  public S_Stock = "All"
  public S_Sales_Person = "All"


  public Y_Date = "";
  public data: any = {};
  public Order_Status = "Pending";
  public Search_User = "All";
  public Approval_Status = "Pending";


  public Customer_Route="";
  public Customer_Area = "All";
  public item_name_disp = "English";

  public T_Date = "";
  public T_Date_Time = "";

  public Default_Payment_ID: string = "Cash";
  public Default_Bank_ID: string = "0";
  public Pay_Date="";
  public Sal_Date="";
  public exp_Date="";
  public Open_Customer_Total = 0;
  public Open_Customer_Length = 0;
  public DB_Expense_Total=0;
  public Emi_Loan_Issue_Total=0;
  public DB_Bank_Row=0;
  public DB_Bank_Total=0;
  Cash_ID = "CASH";
  Cheque_ID = "Cheque";
  Card_ID = "Card";
  public check_Unicode: boolean = false;
  public Stock_Check: boolean = true;

  public Tax_Type = "exclusive";
  public GST_Type = "local";
  public Temp_Order_Row = [];

  add_one: number = 1;
  remove_one: number = -1;
  public cart_item = [

  ];

  

  public Item_Group="";
  public User_Role_Menu = [
    { Menu: 'User Master', Link: '/user-details' },
    { Menu: 'Role Master', Link: '/role-master' },
    { Menu: 'Role Rights', Link: '/role-permissions' }

  ];

  public Stock_Report = [

    { Menu: 'Current Stock', Link: '/report/current-stock', class: '' },
    { Menu: 'Category Stock', Link: '/report/Categorywise-stock', class: '' },
    { Menu: 'Itemwise Stock', Link: '/report/itemwise-stock', class: '' },
    { Menu: 'Stock Report', Link: '/report/stock-report', class: '' }
  ];
  
  public Menu_Master_GF = ['Module', 'Type', 'Display_Name', 'Route_Link', 'Order_No', 'Icon', 'Remark'];
  public back() {
    this._location.back();
  }


  public datefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-dd");
  }

  public datetimefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-ddTHH:mm");
  }
  

  public date_display(data) {
    return this.datePipe.transform(data, "dd-MMM-yyyy");
  }

  public Rights_Name = "";
  public Rights_ID = "";
  public Emp_ID = "";
  public  To_Amt="0";
  public  Ledger_Amt="0";
get_CR_DB_Amount(data)
{
  this.get("Api/Transaction/get_CR_DB_Amount?Ledger_ID=" +data).subscribe((res: any) => {
   this.Ledger_Amt= res;
  });
 this.Default_Bank_ID=data;
}

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }

  F_Row = [];
  get_pos_field(Table, pos) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    this.F_Row = this.F_Row.filter(e => String(e.Posision).toLowerCase() == String(pos).toLowerCase());
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  Reference_Rows = [];
  get_Reference() {


    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;
    });
  }

  Product_Rows = [];
  public isEdit: boolean = false;
  public load_from_make_invoice: boolean = false;



  public User_Master_Rows = [];
  get_User_Master() {
    this.getc("Api/Common/get_User_Master").subscribe((res: any) => {
      this.User_Master_Rows = JSON.parse(res).record;
    });
  }


  public Selected_Company: any = {};
  public Selected_Customer: any = {};
  public Current_User: any = {};

  public Order_No = "0";
  public Menus_Rows: any = {};
  public Menu_Rows = [];
  public Label_Menu_Rows = [];
  get_Role_Rights(Role) {
    this.get("Api/Setting/get_Menu_for_user?Rights=" + Role).subscribe((res: any) => {
      this.Menu_Rows = JSON.parse(res).record;
      this.Side_Menu = true;
      try {
        this.Label_Menu_Rows = this.Menu_Rows.filter(e => e.Type == "Label");
        this.Label_Menu_Rows = this.Label_Menu_Rows.filter(e => e.Parent_ID == "0");
        this.Label_Menu_Rows.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));
      } catch { }

    });
  }


  get_ref(data) {

    
    try{
    return this.Reference_Rows.filter(e => e.Ref_ID == data);
    }catch{

      return [];
    }
  }

  public lastmonthname=""
  get_lastmonthname(data) {
    try{
      const date = new Date();
    date.setMonth(date.getMonth() - 1);

    this.lastmonthname= this.datePipe.transform(date, 'MMMM yyyy')!;
    //return this.Reference_Rows.filter(e => e.Ref_ID == data);
    }catch{

      return [];
    }
  }
  get_child(parent) {
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  get_childs(parent) {
    var data = this.Menu_Rows.filter(e => e.Module == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  public Menu_data = "";
  public Menu_Master_Rows = [];
  public Parent_Menus = [];
  get_Menu_Master() {
    this.isload=true;
    this.getc("Api/Setting/get_Menu_master").subscribe((res: any) => {
      this.isload=false;
      this.Menu_Master_Rows = JSON.parse(res).record;

      this.Parent_Menus = this.Menu_Master_Rows.filter(e => e.Parent_ID == "0");



    });
  }


  loading = false;


  Num(data) {
    return Number(data).toFixed(2);
  }



  public filter_data="";
  pdfType = "Open";


  get(url) {
    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }



  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }


  public load_page(data) {
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page1(data) {
    this.router.navigate([data]);
  }


  public Seraial_No_Settings_Rows = [];
  public Perment_Seraial_No_Settings_Rows = [];
  public Reset_Seraial_No_Settings_Row = [];
  public Orderby_Name = "Name";
  get_Seraial_No_Settings() {

    this.loading = true;
    this.isload = true;
    this.Seraial_No_Settings_Rows = [];
    this.get("Api/Setting/get_Seraial_No_Settings?Order_by=" + this.Orderby_Name).subscribe((res: any) => {
      this.Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Perment_Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Reset_Seraial_No_Settings_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }


  public Variable_Settings_Rows = [];
  public Perment_Variable_Settings_Rows = [];
  public Reset_Variable_Settings_Row = [];
  public Variable_Settings_GF = ['ID', 'S_Variable', 'S_Value', 'S_Default'];

  get_Variable_Settings() {

    this.loading = true;
    this.Variable_Settings_Rows = [];
    this.getc("Api/Setting/get_variable_Settings").subscribe((res: any) => {
      this.Variable_Settings_Rows = JSON.parse(res).record;
      this.Perment_Variable_Settings_Rows = JSON.parse(res).record;
      this.Reset_Variable_Settings_Row = JSON.parse(res).record;
      this.loading = false;

    });
  }

  public Bank_Master_Rows = [];
  get_Bank_Master() {

    this.getc("Api/Master/get_Bank_Master").subscribe((res: any) => {
      this.Bank_Master_Rows = JSON.parse(res).record;
    });
  }


  post_data(data, Table) {

    data.Company = this.Company;
    data.Created_by = this.CREATED_BY;
    data.Table_Name = Table;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.isload = true;
    this.http.post(this.Server_URL + 'api/Master/Post_Data', data, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.isload = false;
          return val;
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public tocken = "";
  get_Token() {



    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public length_of(dt) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return len
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
      } catch { }
    }

    return len;
  }

  load_themes(data) {
    var d = "default.css";
    try {
      d = localStorage.getItem('Theme');
    } catch { }

    if (d != "default.css" && d != null) {

      this.loadStyle(d);

    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  public sum_of(dt, field) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return dt.filteredValue.reduce((sum, current) => sum + parseFloat(current[field]), 0);
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
        return dt.value.reduce((sum, current) => sum + parseFloat(current[field]), 0);
      } catch { }
    }

    return len;
  }



  public Excel_Data: any = {
    'ID': '',
    "item": '',
    "Header": '',
    "Footer": '',
    "Left_Data": '',
    "Right_Data": '',
    "Report_Name": ''

  };
  headers;

  export_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Home/JsontToExcel?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  export_excel1() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Home/JsontToExcel1?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  upload_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel_Upload?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  //loading = false;

  public Dashboard_Row = [];
  get_Dashboard() {
    this.Dashboard_Row = [];
    this.loading = true;

    this.get("Api/Master/get_dashboard?").subscribe((res: any) => {
      this.Dashboard_Row = JSON.parse(res).record;
      this.loading = false;
    });
  }
  File_Name = "Data";

  export_pdf() {
    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdf?" + parm, "_blank");
            this.toastr.success("Data Exported Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  get_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  get_grid_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.GVisible == "True");
    return this.F_Row.sort((a, b) => Number(a["GOrder"]) - Number((b["GOrder"])));

  }

  public Field_Setting = [];
  get_Field_Setting() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {
      this.Field_Setting = JSON.parse(res).record;

    });

  }
  public Field_Setting_Table_Row = [];
  get_Field_Setting_Table() {
    this.getc("Api/Setting/get_Field_Setting_Table").subscribe((res: any) => {
      this.Field_Setting_Table_Row = JSON.parse(res).record;

    });
  }
  Lable_Order_entry="Order Entry";
  Lable_Takeaway="Takeaway";
  Lable_Dining="Dining";
  Lable_Purchase = "Purchase";
  Lable_Sales = "Sales";

  Lable_Receipt = "Receipt";
  Lable_Payment = "Payment";
  Lable_Expence = "Expence";
  Lable_Daybook = "Day Book";
  Lable_Receivable = "Receivable";
  Lable_Payable = "Payable";
  Lable_Item_Master = "Item Master";
  Lable_Ledger = "LedgerMaster";
  Lable_Reports = "Reports";
  Lable_Minimum_Stock = "Minimum Stock";

  public dashboard_data() {
    this.get_Branch_rpt();
    this.get_product('PRODUCT');
    this.get_customer_rpt()
    // this.get_reference();
  }
  public DF_Bill_Type = "Tax Invoice";

  open_pdf(data) {
  }


  public DF_Paymode = "244";
  public ledger_count = 0;
  public Item_Row_Cout=0;
  public DB_payable=0;
  public DB_Customer_Total=0;
  public Total=0;
  public Exp_Total=0;
  public Minimum_Stock_count=0;
  public DB_Vadi_Total = 0;
  public DB_Sales_Date_Diff_= 0;
  public Ledger_Route="";

  public Branch="0";
  public customer="";
  public Acc_Status="1";
  public Product_ID="1";
  public Brand="";
  public Product="";
  public category="";
  public BillType="";
  public Godown="";
  public Name="";
  public city="";
  public radio="";

  public Ledger_Rows1=[];
  public Customer_Master_col_GF=[];

  public monthlypurchase_stock_Row = [];
  get_monthlypurchase_stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_monthlypurchase_stock_rpt").subscribe((res: any) => {
      this.monthlypurchase_stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public item_value_Row = [];
  get_item_value_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_item_value_rpt").subscribe((res: any) => {
      this.item_value_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }
  public product_stock_Row = [];
  get_product_stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_product_stock_rpt").subscribe((res: any) => {
      this.product_stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public Serialnowise_Stock_Row = [];
  get_Serialnowise_stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_Serialnowise_stock_rpt").subscribe((res: any) => {
      this.Serialnowise_Stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }


  public mini_Stock_Row = [];
  get_mini_Stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_mini_Stock_rpt").subscribe((res: any) => {
      this.mini_Stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public Negative_Stock_Row = [];
  get_Negative_Stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_Negative_Stock_rpt").subscribe((res: any) => {
      this.Negative_Stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public vendor_stock_Row = [];
  get_vendor_stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_vendor_stock_rpt").subscribe((res: any) => {
      this.vendor_stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public category_stock_Row = [];
  get_category_stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_category_stock_rpt").subscribe((res: any) => {
      this.category_stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public brand_stock_Row = [];
  get_brand_stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_brand_stock_rpt").subscribe((res: any) => {
      this.brand_stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  
  public purchase_value_Row = [];
  get_purchase_value_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_purchase_value_rpt").subscribe((res: any) => {
      this.purchase_value_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public monthly_stock_Row = [];
  get_monthly_stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_monthly_stock_rpt").subscribe((res: any) => {
      this.monthly_stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public current_stock_Row = [];
  get_current_stock_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_current_stock_rpt").subscribe((res: any) => {
      this.current_stock_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public sales_cuswise_Row = [];
  get_sales_cuswise_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_sales_cuswise_rpt").subscribe((res: any) => {
      this.sales_cuswise_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }


  public Billwise_sales_Row = [];
  get_Billwise_sales_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_Billwise_sales_rpt").subscribe((res: any) => {
      this.Billwise_sales_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public Daily_Report_Row = [];
  get_Daily_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_Daily_rpt?Branch=" + this.Branch).subscribe((res: any) => {
      this.Daily_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public profit_Report_Row = [];
  get_profit_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_profit_rpt?Branch=" + this.Branch).subscribe((res: any) => {
      this.profit_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  
  public product_Report_Row = [];
  get_product_rpt() {
    this.isload = true;
    this.get("Api/Report/get_product_rpt").subscribe((res: any) => {
      this.product_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }
  
  public category_Report_Row = [];
  get_category_rpt() {
    this.isload = true;
    this.get("Api/Report/get_category_rpt").subscribe((res: any) => {
      this.category_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public Day_Book_Row = [];
  get_Day_Book_rpt() {
    this.isload = true;
    this.get("Api/Report/get_Day_Book_rpt").subscribe((res: any) => {
      this.Day_Book_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public Brands_Report_Row = [];
  get_Brands_rpt() {
    this.isload = true;
    this.get("Api/Report/get_Brands_rpt").subscribe((res: any) => {
      this.Brands_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public Branch_Report_Row = [];
  get_Branch_rpt() {
    this.isload = true;
    this.getc("Api/Report/get_Branch").subscribe((res: any) => {
      this.Branch_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public acc_status="0";
  //public billtype_Row = [];
  get_reference() {
    this.isload = true;
    this.getc("Api/Report/get_billtype").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;
      this.isload = false;
    });
  }


  // get_ref(data)
  // {
  //   return this.Reference_Rows.filter(e => e.Code == data);
  // }

  public city_Report_Row = [];
  get_city_rpt() {
    this.isload = true;
    this.get("Api/Report/get_city_rpt").subscribe((res: any) => {
      this.city_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public radio_Row = [];
  get_radio_rpt() {
    this.isload = true;
    this.get("Api/Report/get_radio_rpt").subscribe((res: any) => {
      this.radio_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public BillType_Row = [];
  get_BillType_rpt() {
    this.isload = true;
    this.get("Api/Report/get_BillType_rpt").subscribe((res: any) => {
      this.BillType_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public Godown_Row = [];
  get_Godown_rpt() {
    this.isload = true;
    this.get("Api/Report/get_Godown_rpt").subscribe((res: any) => {
      this.Godown_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public Name_Row = [];
  get_Name_rpt() {
    this.isload = true;
    this.get("Api/Report/get_Name_rpt").subscribe((res: any) => {
      this.Name_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }



  public Customer_Report_Row = [];
  get_customer_rpt() {
    this.isload = true;
    this.get("Api/Report/get_customer_rpt?Branch=" + this.Branch ).subscribe((res: any) => {
      this.Customer_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  
  get_cus(data) {

    
    try{
    return this.Customer_Report_Row.filter(e => (e.PayStatus == data || e.PayStatus=='B'));
    }catch{

      return [];
    }
  }


  public vendor_stock_report_GF = ['Product_Type', 'Brand', 'Product', 'Current_Stock', 'Stock_Value'];
  public vendor_stock_Report_Row = [];
  get_vendorstock_Report() {
    this.isload = true;
    this.get("Api/Report/get_vendorstock_Report?From=" + this.S_From + "&To=" + this.S_To+"&Branch="+this.Branch+"&Acc_Status="+this.acc_status +"&category="+this.category ).subscribe((res: any) => {
      this.vendor_stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  
  public monthly_stock_report_GF = ['Brand', 'Code', 'ProductName'];
  public  monthly_stock_Report_Row = [];
  get_CurrentStock_Monthly() {
    this.isload = true;
    this.get("Api/Report/get_currentstock_Monthly?From=" + this.S_From + "&To=" + this.S_To+"&Branch="+this.Branch+"&BillType="+this.BillType+"&category="+this.category +"&Godown="+this.Godown ).subscribe((res: any) => {
      this. monthly_stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }


  public serial_nowisestock_report_GF = ['Brand', 'Category', 'Code', 'Product_Name', 'Box_Stock', 'Pcs_stock'];
  public serial_nowisestock_Report_Row = [];
  get_serial_nowisestock_Report() {
    this.isload = true;
    this.get("Api/Report/get_currentstock?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&category="+this.category +"&BillType="+this.BillType+"&Godown="+this.Godown).subscribe((res: any) => {      this.serial_nowisestock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }


  public purchase_value_report_GF = ['Brand', 'product_Name', 'OB_Value', 'Stock_In', 'Stock_Out', 'Stock_Value'];
  public purchase_value_Report_Row = [];
  get_purchase_value_Report() {
    this.isload = true;
    debugger
    this.get("Api/Report/get_stock_value?From=" + this.S_From + "&To=" + this.S_To+"&Branch="+this.Branch+"&BillType="+this.BillType +"&category="+this.category+"&Godown="+this.Godown  ).subscribe((res: any) => {
      this.purchase_value_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  } 



  public negative_stock_report_GF = ['Brand', 'Product_Name', 'Opening', 'Purchase_Return', 'Sales', 'Sales_Return', 'Closing','Pcs Stock','Price'];
  public negative_stock_Report_Row = [];
  get_negative_stock() {
    this.isload = true;
    debugger
    this.get("Api/Report/get_negative_stock?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&Acc_status="+this.acc_status).subscribe((res: any) => {
      this. negative_stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public product_wisesales_report_GF = ['Brand', 'Product_Name', 'HSN_Code', 'Basic_Rate', 'Qty', 'CGST', 'SGST','IGST','Net'];
  public product_wisesales_Report_Row = [];
  get_productwisesales_Report() {
    this.isload = true;
    this.get("Api/Report/get_profit_Report?From=" + this.S_From + "&To="+ this.S_To +"&Branch="+this.Branch+"&BillType="+this.BillType+"&category="+this.category +"&Godown="+this.Godown).subscribe((res: any) => {
      this.isload = false;
      this.product_wisesales_Report_Row = JSON.parse(res).record;
    });
  
  }

  
  public profit_report_GF = ['Brand', 'Product_Name', 'HSN_Code', 'Basic_Rate', 'Qty', 'CGST', 'SGST','IGST','Net'];
  public profit_Reports_Row = [];
  get_profit_Report() {
    this.isload = true;
    this.get("Api/Report/get_profit_Report?From=" + this.S_From + "&To="+ this.S_To +"&Branch="+this.Branch+"&BillType="+this.BillType+"&category="+this.category +"&Godown="+this.Godown).subscribe((res: any) => {
      this.profit_Reports_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public Day_Book_GF = ['Brand', 'Product_Name', 'HSN_Code', 'Basic_Rate', 'Qty', 'CGST', 'SGST','IGST','Net'];
  public Day_Books_Row = [];
  get_Day_Book() {
    this.isload = true;
    this.get("Api/Report/get_Day_Book?From=" + this.S_From + "&To="+ this.S_To +"&city="+this.city+"&radio="+this.radio).subscribe((res: any) => {
      this.Day_Books_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }


  public product_stock_report_GF = ['Head', 'Particulars', 'Date', 'Party_Name', 'Stock_In', 'Stock_Out', 'Value'];
  public product_stock_Report_Row = [];
  get_product_stock_Report() {
    this.isload = true;
    this.get("Api/Report/get_product_stock_Report?From=" + this.S_From + "&To=" + this.S_To+"&Branch="+this.Branch+"&Acc_status="+this.acc_status +"&category="+this.category).subscribe((res: any) => {

      this. product_stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }




  public monthly_purchasestock_report_GF = ['Brand', 'product_Name', 'May_Closing', 'Stock_In', 'Stock_Out', 'Stock_Value'];
  public  monthly_purchasestock_Report_Row = [];
  get_monthly_purchasestock_Report() {
    this.isload = true;
    this.get("Api/Report/get_monthly_purchasestock_Report?From=" + this.S_From + "&To=" + this.S_To+"&Branch="+this.Branch+"&BillType="+this.BillType+"&category="+this.category +"&Godown="+this.Godown ).subscribe((res: any) => {
      this. monthly_purchasestock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public mini_stock_report_GF = ['Brand', 'Product_Name', 'Current_Stock', 'Pcs_Stock', ' Mini_Qty', 'Required_stock'];
  public mini_stock_Report_Row = [];
  get_mini_stock_Report() {
    this.isload = true;
    this.get("Api/Report/get_mini_stock_Report?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&Acc_status="+this.acc_status).subscribe((res: any) => {
      this.mini_stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
   }
  public item_basevalue_report_GF = ['Brand', 'Product_Name', 'Current_Stock', 'Pcs_Stock',  'Stock_Value'];
  public item_basevalue_Report_Row = [];
  get_item_basevalue_Report() {
    this.isload = true;
    this.get("Api/Report/get_item_basevalue_Report?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&Acc_Status="+this.acc_status +"&category="+this.category).subscribe((res: any) => {
      this.item_basevalue_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
   }

  public daily_sales_report_GF = ['BillNo', 'Date', 'Customer', 'Product', 'Qty', 'Amount', 'GST_Amount','Net'];
  public daily_sales_Report_Row = [];
  public daily_sales_Report_bill = [];
  get_daily_sales_Report() {
    this.isload = true;
    this.get("Api/Report/get_daily_sales_Report?From=" + this.S_From + "&To=" + this.S_To + "&Branch="+this.Branch+"&Acc_status="+this.acc_status ).subscribe((res: any) => {
      this.isload = false;
      this.daily_sales_Report_Row = JSON.parse(res).record;
      
      var Item = [...new Set(this.daily_sales_Report_Row.map(item => item.BillNo))];
      this.daily_sales_Report_bill = [];
      for (let data of Item) {
        var rs=this.daily_sales_Report_Row.filter (e=>e.BillNo==data)
        // var Amount_ = (this.daily_sales_Report_bill.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        // var Qty_ = (this.daily_sales_Report_bill.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        // var Profit_ = (this.daily_sales_Report_bill.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        // var S_Rate_ = (this.daily_sales_Report_bill.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.S_Rate), 0)).toFixed(2);

        this.daily_sales_Report_bill.push({ BillNo: data ,'Customer' : rs[0].Customer,'Date' : rs[0].Date})
      }

    });
 
  }

  public Billwise_sales_report_GF = ['BillNo', 'Date', 'Customer', 'GSTNo', 'Qty', 'Amount', 'CGST','SGST','IGST','Net','PaidAmt','PurRate'];
  public Billwise_sale_Report_Row = [];
  get_sales_Billwise() {
    this.isload = true;
    this.get("Api/Report/Pr_fetch_sales_Invoicewise?From=" + this.S_From + "&To=" + this.S_To + "&Branch="+this.Branch+"&Acc_status="+this.acc_status  ).subscribe((res: any) => {
      this.Billwise_sale_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  
  public sales_prodwise_GF = ['ProductName', 'Brand'];
  public sales_prodwise_Row = [];
  get_sales_prodwise() {
    this.isload = true;
    debugger
    this.get("Api/Report/get_sales_prodwise?From=" + this.S_From + "&To=" + this.S_To + "&Branch="+this.Branch+"&Acc_status="+this.acc_status).subscribe((res: any) => {
      this.sales_prodwise_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public sales_categorywise_GF = ['ProductName', 'Brand'];
  public sales_categorywise_Row = [];
  get_sales_categorywise() {
    this.isload = true;
    debugger
    this.get("Api/Report/get_sales_categorywise?From=" + this.S_From + "&To=" + this.S_To + "&Branch="+this.Branch+"&Acc_status="+this.acc_status).subscribe((res: any) => {
      this.sales_categorywise_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public customer_wisesales_report_GF = ['Product_Name', 'Qty', 'Amount', 'GST', 'Net'];
  public customer_wisesales_Report_Row = [];
  get_customer_wisesales_Report() {
    this.isload = true;
    this.get("Api/Report/get_cuswise_sales?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&Customer="+this.customer+"&Acc_status="+this.acc_status).subscribe((res: any) => {
      this.customer_wisesales_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }



  public customer_productwisesales_report_GF = ['Bill_Number', 'Date', 'Customer', 'product', 'Qty', 'Rate', 'Amount','GST_Amount','Net'];
  public customer_productwisesales_Report_Row = [];
  get_Cus_ProdWise_Sales() {
    this.isload = true;
    this.get("Api/Report/get_cuswise_sales?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&Customer="+this.customer+"&Acc_status="+this.acc_status).subscribe((res: any) => {
      this.customer_productwisesales_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }

  public I_Category = "All";
  public I_Brand = "All";
  public S_Item_ID = "";
  public S_Item_Name = "";


  public current_stock_report_GF = ['Brand', 'Category', 'Code', 'ProductName'];
  public current_stock_Report_Row = [];
  get_currentstock() {
    this.isload = true;
    this.get("Api/Report/get_currentstock?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&category="+this.category +"&BillType="+this.BillType+"&Godown="+this.Godown).subscribe((res: any) => {
      this.current_stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  
  public Product_Master_Row = [];
  get_product(data) {
    this.isload = true;
    this.get("Api/Master/get_product?Category="+data).subscribe((res: any) => {
      this.Product_Master_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  // public productid="";
  public product_transaction_GF = ['Brand', 'Category', 'Code', 'ProductName'];
  public product_transaction_Row = [];
  get_product_transaction() {
    // debugger
    this.isload = true;
    this.get("Api/Report/get_product_transaction?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&productid="+this.Product_ID +"&BillType="+this.BillType).subscribe((res: any) => {
      this.product_transaction_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }

  public category_stock_report_GF = ['Code', 'product_Name', 'Opening', 'Stock_In', 'Box_Stock_out','Pcs_Stock_out','Box_Stock','Pcs_Stock'];
  public category_stock_Report_Row = [];
  get_category_stock_Report() {
    this.isload = true;
    this.get("Api/Report/get_category_stock_Report?From=" + this.S_From + "&To=" + this.S_To +"&Branch="+this.Branch+"&Acc_Status="+this.acc_status +"&category="+this.category).subscribe((res: any) => {
      debugger
      this.category_stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
   }

 

  public Brand_report_GF = ['Category', 'Code', 'product_Name', 'Opening', 'Stock_In', 'Box_Stock_out','Pcs_Stock_out','Box_Stock','Pcs_Stock'];
  public Brand_Report_Row = [];
  get_Brandstock_Report() {
    this.isload = true;
    this.get("Api/Report/get_currentstock?From=" + this.S_From + "&To=" + this.S_To+"&Branch="+this.Branch+"&category="+this.category +"&BillType="+this.BillType+"&Godown="+this.Godown).subscribe((res: any) => {
      this.Brand_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });
  
  }
  
  public Daily_Sales_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
     {Field:'BillNo',Name:'Bill No', Align:''},
    {Field:'Date',Name:'Date ', Align:''},
    {Field:'Customer',Name:'Customer    ', Align:''},
    {Field:'Product',Name:'Product     ', Align:''},
    {Field:'Qty',Name:'Qty ', Align:'Right'},
    {Field:'Net',Name:'Net  ', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]
  
  public BillWise_Sales_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
     {Field:'BillNo',Name:'Bill No', Align:''},
    {Field:'Date',Name:'Date ', Align:''},
    {Field:'Customer',Name:'Customer    ', Align:''},
    // {Field:'Product',Name:'Product     ', Align:''},
    {Field:'Qty',Name:'Qty ', Align:'Right'},
    {Field:'Net',Name:'Net  ', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]
  
  public ProdWise_Sales_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
     {Field:'Brand',Name:'Brand', Align:''},
    {Field:'ProductName',Name:'Product Name ', Align:''},
    {Field:'BasicRate',Name:'Basic Rate', Align:''},
    // {Field:'Product',Name:'Product     ', Align:''},
    {Field:'Qty',Name:'Qty ', Align:'Right'},
    {Field:'Net',Name:'Net  ', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]
  
  public product_stock_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
     {Field:'Brand',Name:'Brand        ', Align:''},
    {Field:'ProductName',Name:'Product Name', Align:''},
    {Field:'Box Stock',Name:'Box Stock', Align:'Right'},
    {Field:'PCS Stock',Name:'PCS Stock', Align:'Right'},
    {Field:'Purchase Based',Name:'Purchase Based', Align:'Right'},
    {Field:'Product Based',Name:'Product Based', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]
  
  public stock_value_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
     {Field:'Brand',Name:'Brand        ', Align:''},
    {Field:'Product',Name:'Product Name', Align:''},
    {Field:'OB Value',Name:'OB Value', Align:'Right'},
    {Field:'Stock IN',Name:'Stock IN', Align:'Right'},
    {Field:'Stock OUT',Name:'Stock Out', Align:'Right'},
    {Field:'Stock Value',Name:'Stock Value', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]
  
  public Negative_stock_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
     {Field:'Brand',Name:'Brand        ', Align:''},
    {Field:'ProductName',Name:'Product Name', Align:''},
    {Field:'Opening',Name:'Opening', Align:'Right'},
    {Field:'Stock IN',Name:'StockIN', Align:'Right'},
    {Field:'Box Stock OUT',Name:'Box Out', Align:'Right'},
    {Field:'PCS Stock OUT',Name:'PCS Out', Align:'Right'},
    {Field:'Box Stock',Name:'Box Stock', Align:'Right'},
    {Field:'PCS Stock',Name:'PCS Stock', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]

  public Minimum_stock_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
     {Field:'Brand',Name:'Brand        ', Align:''},
    {Field:'ProductName',Name:'Product Name', Align:''},
    {Field:'CurrentStock',Name:'BoxStock', Align:'Right'},
    {Field:'PCS Stock',Name:'PCSStock', Align:'Right'},
    {Field:'MinQty',Name:'MinQty', Align:'Right'},
    {Field:'RequiredStock',Name:'StockReq', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]

  public prodwise_transact_Export=[
    // {Field:'SNo',Name:'S.No', Align:''},
     {Field:'Head',Name:'Head  ', Align:''},
    {Field:'Particulars',Name:'Particulars', Align:''},
    {Field:'Date',Name:' Date ', Align:'Center'},
    {Field:'PartyName',Name:'Party Name   ', Align:''},
    {Field:'StockIN',Name:'  IN  ', Align:'Right'},
    {Field:'StockOUT',Name:' OUT ', Align:'Right'},
    {Field:'Balance',Name:'  Bal ', Align:'Right'},
    //{Field:'sal_paymode',Name:'Pay Mode', Align:''},
  ]


}
